export default {
  BOOKING_TECHNICAL: {
    label: "Booking Technical",

    content: {
      TECHNICAL_WARN_HEADER: {
        group: "BOOKING_TECHNICAL",
        label: "Warning/Red Header",
        inputType: "RICHTEXT",
      },
      TECHNICAL_ATTENTION: {
        group: "BOOKING_TECHNICAL",
        label: "Attention Header",
        inputType: "RICHTEXT",
      },
      TECHNICAL_FOOTER: {
        group: "TECHNICAL_FOOTER",
        label: "Technical Footer",
        inputType: "RICHTEXT",
      },
    },
  },
};
