<template>
  <div>
    <v-row justify="space-between">
      <h3 class="title white--text">Text Configuration</h3>
    </v-row>
    <v-row class="subtitle-2 white--text"> Autofill text customization. </v-row>

    <div v-for="(g, key) in groups" :key="key" class="ring">
      <div class="white--text pa-2">{{ g.label }}</div>
      <a-card
        v-for="(item, pathname) in g.content"
        :key="pathname"
        class="hover"
        @click.native="$router.push({ name: 'admin_textconfig_edit', params: { groupkey: key, refkey: pathname } })"
      >
        {{ item.label }}
      </a-card>
    </div>
  </div>
</template>

<script>
import TEXTCONFIG from "@/config/TEXTCONFIG_CONFIG";
export default {
  data() {
    return {
      groups: TEXTCONFIG,
    };
  },
};
</script>
